import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { logger } from 'client/utils/isomorphic-logger';

import './html-video-player.scss';

export function HtmlVideoPlayer(props) {
  const {
    src,
    track,
    loop,
    autoPlay,
    muted,
    className,
    preload,
    playsInline,
    playback,
    onTimeUpdate,
    ...restProps
  } = props;

  const videoRef = useRef(null);
  const playPromise = useRef(null);

  function onTimeUpdateHandler() {
    const { duration, currentTime } = videoRef.current;
    onTimeUpdate({ duration, currentTime });
  }

  useEffect(() => {
    if (playback === null) {
      return;
    }

    if (playback) {
      playPromise.current = videoRef.current.play();
      playPromise.current.catch(err => {
        logger('warn', err);
      });
    } else if (playPromise.current) {
      playPromise.current
        .then(() => {
          videoRef.current.pause();
          playPromise.current = null;
        })
        .catch(err => {
          logger('warn', err);
        });
    }
  }, [playback]);

  return (
    <div>
      <video
        crossOrigin=""
        controls
        ref={videoRef}
        src={src}
        autoPlay={autoPlay}
        loop={loop}
        className={className}
        muted={muted}
        preload={preload}
        playsInline={playsInline}
        onTimeUpdate={onTimeUpdateHandler}
        {...restProps}
      >
        <track default kind="captions" srcLang="en" src={track} />
      </video>
    </div>
  );
}

HtmlVideoPlayer.propTypes = {
  src: PropTypes.string.isRequired,
  track: PropTypes.string,
  poster: PropTypes.string,
  loop: PropTypes.bool,
  autoPlay: PropTypes.bool,
  preload: PropTypes.string,
  muted: PropTypes.bool,
  className: PropTypes.string,
  playsInline: PropTypes.bool,
  playback: PropTypes.bool,
  onTimeUpdate: PropTypes.func,
};

HtmlVideoPlayer.defaultProps = {
  track: '',
  poster: '',
  loop: false,
  autoPlay: false,
  preload: 'auto',
  muted: true,
  className: 'w-100 video-js',
  playsInline: false,
  playback: null,
  onTimeUpdate: noop,
};
