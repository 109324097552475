import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './clipped-container.scss';

export function ClippedContainer({ mask, containerClasses, children }) {
  if (!mask) {
    return children;
  }
  const { name: clipId, path } = mask;

  return (
    <Fragment>
      <svg style={{ display: 'block' }} width={0} height={0}>
        <defs>
          <clipPath id={clipId} clipPathUnits="objectBoundingBox">
            <path d={path} />
          </clipPath>
        </defs>
      </svg>
      <div
        className={classnames('clipped-container inner-bg', containerClasses)}
        style={{ clipPath: `url(#${clipId})` }}
      >
        {children}
      </div>
    </Fragment>
  );
}

ClippedContainer.propTypes = {
  mask: PropTypes.shape({
    name: PropTypes.string,
    path: PropTypes.string,
  }),
  containerClasses: PropTypes.string,
  children: PropTypes.node,
};

ClippedContainer.defaultProps = {
  mask: null,
  containerClasses: '',
  children: null,
};
