import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import debounce from 'lodash/debounce';

import './vehicle-showcase-controls-mobile.scss';

const SCROLL_OFFSET = 180;

export function VehicleShowcaseControlsMobile({ slides, updateSelectedSlide, selectedSlideIndex }) {
  const listRef = useRef();
  const [leftOverlay, setLeftOverlay] = useState(false);
  const [rightOverlay, setRightOverlay] = useState(false);

  const setOverlays = useCallback(() => {
    const { scrollLeft, scrollWidth, clientWidth } = listRef.current;
    const maxScrollLeft = scrollWidth - clientWidth;

    setLeftOverlay(scrollLeft > 0);
    setRightOverlay(scrollLeft < maxScrollLeft);
  }, []);

  const scrollHandler = useCallback(debounce(setOverlays, 100), [setOverlays]);

  const handleLeftBtnClick = useCallback(() => {
    const element = listRef.current;
    element.scrollLeft -= SCROLL_OFFSET;
  }, []);

  const handleRightBtnClick = useCallback(() => {
    const element = listRef.current;
    element.scrollLeft += SCROLL_OFFSET;
  }, []);

  useEffect(setOverlays, [setOverlays, slides]);

  useEffect(() => {
    const rect = document.querySelector('.vehicle-showcase-controls-mobile .active').getBoundingClientRect();
    listRef.current.scrollLeft += rect.x - 48;
  }, [selectedSlideIndex]);

  return (
    <div className="pos-r overflow-x-hidden vehicle-showcase-controls-mobile mb-1_75 mx-0_5">
      <div
        className={classnames('overlay pos-a top-0 left-0 text-left d-flex align-items-center left', {
          invisible: !leftOverlay,
        })}
      >
        <button
          type="button"
          className="scroll-btn d-flex pr-1 pl-0"
          aria-hidden
          aria-label="left scroll"
          tabIndex={-1}
          onClick={handleLeftBtnClick}
        >
          <i className="medium text-primary icon-arrow-left4" aria-hidden />
        </button>
      </div>
      <nav ref={listRef} onScroll={scrollHandler} className="d-flex nav-list text-nowrap">
        {slides.map((slide, index) => (
          <button
            key={slide.metadata('thumbTitle').value()}
            className={classnames('nav-btn medium font-weight-medium text-cool-gray-40 px-1_25 py-0_25', {
              'active text-shadow-contrast': index === selectedSlideIndex,
            })}
            value={index}
            data-skip-tracking="true"
            aria-pressed={index === selectedSlideIndex}
            onClick={updateSelectedSlide}
          >
            {slide.metadata('thumbTitle').value()}
          </button>
        ))}
      </nav>
      <div
        className={classnames(
          'overlay pos-a top-0 right-0 text-right d-flex align-items-center justify-content-end right',
          {
            invisible: !rightOverlay,
          }
        )}
      >
        <button
          type="button"
          className="scroll-btn d-flex pl-1 pr-0"
          aria-hidden
          aria-label="right scroll"
          tabIndex={-1}
          onClick={handleRightBtnClick}
        >
          <i className="medium text-primary icon-arrow-right4" aria-hidden />
        </button>
      </div>
    </div>
  );
}

VehicleShowcaseControlsMobile.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  updateSelectedSlide: PropTypes.func.isRequired,
  selectedSlideIndex: PropTypes.number.isRequired,
};
