export const CREATIVE_ID = 'edm-entry-home-vehicle-showcase';

const BG_NAME = 'vs-bg';
const LG_BG_NAME = `${BG_NAME}-lg`;
export const LG_BG_SLIDES = [
  {
    name: `${LG_BG_NAME}-0`,
    path:
      'M0.999,0.936 c-0.135,0.015,-0.205,0.054,-0.268,0.064 c-0.079,0.012,-0.138,-0.004,-0.305,0 C0.264,1,0.123,0.967,0,0.927 V0.054 C0.072,0.015,0.132,0.004,0.185,0.005 C0.238,0.006,0.313,0.011,0.386,0.064 c0.025,0.018,0.048,0.04,0.072,0.066 c0.02,0.022,0.042,0.048,0.065,0.075 c0.084,0.101,0.187,0.223,0.294,0.254 c0.012,0.003,0.072,-0.002,0.183,-0.013',
  },
  {
    name: `${LG_BG_NAME}-1`,
    path:
      'M0,0.004 c0.059,0.058,0.115,0.13,0.164,0.195 c0.023,0.03,0.045,0.058,0.065,0.083 c0.078,0.095,0.158,0.147,0.274,0.153 c0.116,0.015,0.196,-0.023,0.276,-0.104 c0.02,-0.021,0.042,-0.045,0.066,-0.071 c0.022,-0.024,0.103,-0.101,0.156,-0.152 v0.789 c-0.149,0.055,-0.607,0.197,-1,0.019',
  },
  {
    name: `${LG_BG_NAME}-2`,
    path:
      'M1,0.073 C0.93,0.006,0.861,0.001,0.755,0.005 c-0.116,-0.004,-0.196,0.031,-0.275,0.117 C0.46,0.145,0.438,0.171,0.415,0.198 c-0.085,0.101,-0.189,0.225,-0.297,0.255 c-0.024,0.007,-0.078,0.003,-0.116,0.001 l0,0.446 c0.262,0.026,0.316,0.103,0.511,0.103 c0.181,0,0.392,-0.078,0.489,-0.112',
  },
  {
    name: `${LG_BG_NAME}-3`,
    path:
      'M-0.003,0.919 c0.553,0.127,0.855,0.075,1,0.016 V0.468 c-0.11,0.006,-0.277,0.028,-0.487,-0.056 C0.315,0.335,0.102,0.164,-0.003,-0.003 l0,0 L-0.003,0.919',
  },
];

const MD_BG_NAME = `${BG_NAME}-md`;
export const MD_BG_SLIDES = [
  {
    name: `${MD_BG_NAME}-0`,
    path:
      'M1,0.369 C0.951,0.331,0.904,0.293,0.859,0.257 c-0.043,-0.035,-0.083,-0.068,-0.12,-0.096 C0.595,0.051,0.448,-0.005,0.235,0 C0.226,0,0.217,0,0.208,0 h-0.002 C0.134,0,0.065,0.007,0,0.021 v0.956 c0.159,0.039,0.462,-0.003,0.717,0.003 c0.155,0.004,0.223,0.012,0.283,0.02',
  },
  {
    name: `${MD_BG_NAME}-1`,
    path:
      'M0,0.42 C0.081,0.365,0.156,0.3,0.225,0.241 c0.038,-0.033,0.075,-0.064,0.108,-0.09 C0.464,0.048,0.596,-0.005,0.789,0 C0.797,0,0.805,0,0.814,0 c0,0,0,0,0,0 c0.065,0,0.127,0.007,0.186,0.02 v0.906 c-0.13,0.029,-0.284,0.059,-0.434,0.074 H0.217 C0.139,0.991,0.073,0.975,0,0.959',
  },
  {
    name: `${MD_BG_NAME}-2`,
    path:
      'M0,0.952 c0.368,0.074,0.729,0.052,1,0.01 l0,-0.691 c-0.105,0.051,-0.219,0.072,-0.367,0.059 c-0.202,-0.008,-0.341,-0.072,-0.477,-0.188 c-0.035,-0.03,-0.073,-0.065,-0.113,-0.102 l0,0 C0.029,0.026,0.014,0.013,0,0',
  },
  {
    name: `${MD_BG_NAME}-3`,
    path:
      'M0,0.625 c0.064,0.02,0.136,0.027,0.221,0.019 c0.159,-0.006,0.268,-0.056,0.375,-0.147 c0.028,-0.023,0.057,-0.05,0.089,-0.08 l0,0 c0.084,-0.077,0.18,-0.166,0.283,-0.224 c0.004,-0.003,0.009,-0.004,0.014,-0.005 l0.019,-0.006 c0,0,0,0.026,0,0.056 v0.83 c-0.006,0.006,-0.013,0.011,-0.02,0.013 c-0.143,0.054,-0.291,0.085,-0.433,0.1 H0.052 c-0.018,-0.002,-0.035,-0.004,-0.052,-0.006',
  },
];

const SM_BG_NAME = `${BG_NAME}-sm`;
const MOBILE_MASK =
  'M1,0.983 V0.001 c-0.191,0.022,-0.366,0.052,-0.541,0.091 C0.356,0.115,0.244,0.142,0.125,0.171 C0.084,0.181,0.043,0.191,0,0.201 v0.789 c0.148,0.007,0.285,0.011,0.416,0.011 c0.001,0,0.002,0,0.003,0 c0.069,0,0.137,-0.001,0.205,-0.004 C0.724,0.993,0.853,0.988,1,0.983';
export const MOBILE_BG_SLIDES = [
  {
    name: `${SM_BG_NAME}-0`,
    path: MOBILE_MASK,
  },
  {
    name: `${SM_BG_NAME}-1`,
    path: MOBILE_MASK,
  },
  {
    name: `${SM_BG_NAME}-2`,
    path: MOBILE_MASK,
  },
  {
    name: `${SM_BG_NAME}-3`,
    path: MOBILE_MASK,
  },
];
