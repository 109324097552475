import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { stripHtml } from 'client/utils/string-utils';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';

export function VehicleShowcaseSlideContent({
  headline,
  oemUrl,
  ctaText,
  ctaUrl,
  ctaUrlMobile,
  copyText,
  detailsText,
  detailsUrl,
  detailsUrlMobile,
  ctaTargetNew,
  position,
  isMobile,
  isActive,
  className,
  style,
  tabId,
}) {
  const finalUrl = isMobile ? ctaUrlMobile : ctaUrl;
  const finalDetailsUrl = isMobile ? detailsUrlMobile : detailsUrl;
  const tabIndex = isActive ? 0 : -1;
  const targetNew = ctaTargetNew === 'true';

  const ctaProps = {
    href: encodeURI(finalUrl),
    rel: 'nofollow',
    'data-tracking-id': targetNew ? 'vehicle_showcase_click_out' : 'vehicle_showcase_click_in',
    'data-tracking-value': `main_${ctaText}`,
    'data-tracking-position': `${position}`,
    target: targetNew ? '_blank' : '_self',
    tabIndex,
  };

  const ariaProps = tabId
    ? {
        role: 'tabpanel',
        id: `tabpanel-${tabId}`,
        'aria-hidden': !isActive,
        'aria-labelledby': `tab-${tabId}`,
      }
    : {};

  return (
    <div className={classnames('h-100 d-flex flex-column px-0_25 w-100', className)} style={style} {...ariaProps}>
      <h2
        className="hidden-sm-up display-2 font-weight-bold mb-1 mb-md-1_25"
        tabIndex={isActive ? 0 : -1} /* eslint-disable-line jsx-a11y/no-noninteractive-tabindex */
      >
        <ContentFragment componentToUse="span">{headline}</ContentFragment>
      </h2>
      {!!copyText && <ContentFragment classes="mb-1">{copyText}</ContentFragment>}
      {finalDetailsUrl && detailsText && (
        <div className="mb-1_5">
          <a
            className="details-url text-underline mb-1_5"
            href={encodeURI(finalDetailsUrl)}
            rel="nofollow"
            target="_self"
            data-tracking-id="vehicle_showcase_click_in"
            tabIndex={tabIndex}
          >
            {detailsText}
            <i className="icon-arrow-right3 small ml-0_25" aria-hidden />
          </a>
        </div>
      )}
      <a
        {...ctaProps}
        aria-label={stripHtml(`${ctaText} about ${headline}`)}
        className="w-100 d-flex align-items-center justify-content-center text-decoration-none mt-auto py-0_75 px-1_5 rounded text-shadow-contrast text-white font-weight-bold learn-more"
      >
        {ctaText}
        {targetNew && <i className="icon-open_in_new ml-0_25" aria-hidden />}
      </a>
      <div className="mt-0_5 text-center xsmall text-blue-30">
        {oemUrl && <a {...ctaProps}>{oemUrl}</a>}
        &nbsp;
      </div>
    </div>
  );
}

VehicleShowcaseSlideContent.propTypes = {
  headline: PropTypes.string.isRequired,
  oemUrl: PropTypes.string,
  ctaText: PropTypes.string.isRequired,
  ctaUrl: PropTypes.string.isRequired,
  ctaUrlMobile: PropTypes.string.isRequired,
  copyText: PropTypes.string,
  detailsText: PropTypes.string,
  detailsUrl: PropTypes.string,
  detailsUrlMobile: PropTypes.string,
  ctaTargetNew: PropTypes.string,
  isActive: PropTypes.bool,
  isMobile: PropTypes.bool,
  position: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.shape({}),
  tabId: PropTypes.string,
};

VehicleShowcaseSlideContent.defaultProps = {
  oemUrl: null,
  copyText: null,
  detailsText: null,
  detailsUrl: null,
  detailsUrlMobile: null,
  ctaTargetNew: null,
  isActive: false,
  isMobile: false,
  position: '1',
  className: null,
  style: null,
  tabId: null,
};
