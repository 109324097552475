import { getCurrentDate } from 'site-modules/shared/utils/date-utils';
/**
 * Path to editorial content for research categories
 * @type {string}
 */
export const RESEARCH_CATEGORY_EDITORIAL_PATH = 'editorial/includes/categories/research';
export const RESEARCH_CUSTOM_ADS_PATH = 'editorial/includes/venom/custom-ads';
export const RESEARCH_LINK_WIDGET_EDITORIAL_PATH = 'home-page-content';
export const CAR_NEWS_CATEGORY_EDITORIAL_PATH = 'editorial/includes/categories/car-news';
export const RANDOMIZED_MAKES_PATH = 'randomizedMakes.Count.8';
export const RANDOMIZED_MMY_PATH = `randomizedMakeModel.${getCurrentDate().getFullYear()}.Count.8`;
export const AD_UNIT_LIMIT = 5;
export const NUMBER_OF_ENTRIES_BETWEEN_AD_UNITS = 4;
export const ENTRIES_TO_AVOID = [/editorial-high-impact/];
export const CAR_NEWS_CATEGORY_NAME = 'car-news';
export const TEST_CAR_NEWS_CATEGORY_NAME = 'test-car-news';
export const SUB_CATEGORIES = {
  firstImpressions: 'first-impressions',
};
export const OVERVIEW_EDITORIAL_SLUGS = {
  research: 'research',
  carNews: 'car-news',
};
export const OVERVIEW_EDITORIAL_TITLES = {
  research: 'research',
  carNews: 'car news',
};
