import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Slider from 'react-slick';

import { useOnScreen } from 'site-modules/shared/hooks/use-on-screen';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './vehicle-showcase-view-mobile.scss';

export function VehicleShowcaseViewMobile({ children, updateSelectedSlideMobile, selectedSlideIndex }) {
  const sliderRef = useRef();
  const dotsRef = useRef();

  const [isIntersecting] = useOnScreen(dotsRef, {
    root: null,
    rootMargin: '0px',
    threshold: 0.1,
  });

  useEffect(() => {
    sliderRef.current.slickGoTo(selectedSlideIndex);
  }, [selectedSlideIndex]);

  function appendDots(dots) {
    return (
      <div aria-hidden ref={dotsRef}>
        <ul className="slick-dots">{dots}</ul>
      </div>
    );
  }

  const sliderOptions = {
    dots: true,
    dotsClass: classnames('mt-0_5 meatballs', { 'is-intersecting': isIntersecting }),
    appendDots,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: true,
    swipe: true,
    beforeChange: updateSelectedSlideMobile,
  };

  return (
    <Slider ref={sliderRef} className="vehicle-showcase-slider" {...sliderOptions}>
      {children}
    </Slider>
  );
}

VehicleShowcaseViewMobile.propTypes = {
  children: PropTypes.node.isRequired,
  updateSelectedSlideMobile: PropTypes.func.isRequired,
  selectedSlideIndex: PropTypes.number.isRequired,
};
