import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './aspect-ratio-container.scss';

/**
 * Component that adds an extra container around children associated with known aspect ratio. Use cases includes CLS mitigation.
 * Reference: https://developer.mozilla.org/en-US/docs/Web/CSS/aspect-ratio
 *
 * @param {Node} children element.
 * @param {String} aspectRatio, i.e. aspect-ratio, '[width]:[height]' examples: '16:9', '4:3'
 * @param {String} className, names of classes
 * @param {String,Function} Tag, name of tag, defaults to 'div'
 * @param {Object} styles, optional inline styles
 * @param {Object<props>} otherProps
 *
 * @example basic
 * <AspectRatioContainer aspectRatio="54:25">
 *    <img ... />
 * </AspectRatioContainer>
 *
 * @example usage with figure
 * <AspectRatioContainer aspectRatio="54:25" tag="figure">
 *    <img ... />
 *    <figcaption .../>
 * </AspectRatioContainer>
 *
 * @example usage with animation
 * <AspectRatioContainer aspectRatio="54:25" className="hvs-animation-container m-0" tag="figure">
 *    {hvsAnimationImagesArray.map((src, index) => (
 *      <img key={src} className={`w-100 img-${index}`} src={src} alt={`${hvsVehicle}-${index + 1}`} />
 *    ))}
 * </AspectRatioContainer>
 */

export function AspectRatioContainer({ children, aspectRatio, className, tag: Tag, styles, ...otherProps }) {
  const [aspectWidth, aspectHeight] = aspectRatio.split(':');
  const defaultStyles = {
    paddingTop: `${parseFloat(aspectHeight / aspectWidth).toFixed(4) * 100}%`,
    overflow: 'hidden',
  };
  const tagStyles = {
    ...defaultStyles,
    ...styles,
  };
  // this below inline-style is necessary due to aspect-ratio-container scss not available before image loads
  // height is overridden with auto when SCSS file loads
  const wrapperDefaultStyles = { height: 0, overflow: 'hidden' };

  return (
    <Tag className={classnames('aspect-ratio-container pos-r', className)} style={tagStyles} {...otherProps}>
      <div className="wrapper" style={wrapperDefaultStyles}>
        {children}
      </div>
    </Tag>
  );
}

AspectRatioContainer.propTypes = {
  children: PropTypes.node,
  aspectRatio: PropTypes.string,
  className: PropTypes.string,
  tag: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  styles: PropTypes.shape({}),
};

AspectRatioContainer.defaultProps = {
  children: null,
  aspectRatio: '16:9',
  className: null,
  tag: 'div',
  styles: null,
};
