import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { IntersectionObserver } from 'client/utils/intersection-observer';

import './content-fragment.scss';

export class ContentFragment extends Component {
  componentDidMount() {
    const images = this.container.querySelectorAll('img[data-dsrc]');
    const intxnConfig = {
      // If the image gets within 50px in the Y axis, start the download.
      rootMargin: '50px 0px',
      threshold: 0.01,
    };

    function onIntersection(entries, observer) {
      // Loop through the entries
      entries.forEach(entry => {
        // Are we in viewport?
        if (entry.intersectionRatio > 0) {
          // Stop watching and load the image
          observer.unobserve(entry.target);
          const img = entry.target;
          img.src = img.dataset.dsrc;
        }
      });
    }

    if (IntersectionObserver) {
      this.imgObserver = new IntersectionObserver(onIntersection, intxnConfig);

      // NodeList.forEach is not supported in IE
      Array.prototype.forEach.call(images, image => {
        this.imgObserver.observe(image);
      });
    }
  }

  componentWillUnmount() {
    if (this.imgObserver) {
      this.imgObserver.disconnect();
    }
  }

  render() {
    const { children, classes, inlineStyle, componentToUse, href, id, type, componentProps } = this.props;

    return React.createElement(componentToUse, {
      ...componentProps,
      className: classnames('content-fragment', classes),
      style: inlineStyle,
      ref: c => {
        this.container = c;
      },
      dangerouslySetInnerHTML: { __html: typeof children === 'string' ? unescape(children) : children },
      href,
      id,
      type,
    });
  }
}

ContentFragment.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  inlineStyle: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  componentToUse: PropTypes.string,
  href: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  componentProps: PropTypes.shape({}),
};

ContentFragment.defaultProps = {
  classes: '',
  inlineStyle: null,
  componentToUse: 'div',
  href: null,
  id: null,
  type: null,
  componentProps: undefined,
};
